export const PaymentList = [
  {
    img: "https://images.linkcdn.cloud/global/payment/V2/PHP/epayment/gcash.webp",
    alt: "gcash",
  },
  {
    img: "https://images.linkcdn.cloud/global/payment/V2/PHP/epayment/payprophpn.webp",
    alt: "gcash",
  },
  {
    img: "https://images.linkcdn.cloud/global/payment/V2/PHP/epayment/payprophpd.webp",
    alt: "gcash",
  },
  {
    img: "https://images.linkcdn.cloud/global/payment/V2/PHP/epayment/maya.webp",
    alt: "gcash",
  },
  {
    img: "https://images.linkcdn.cloud/global/payment/V2/PHP/bank/union.webp",
    alt: "gcash",
  },
];
