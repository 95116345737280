import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import "swiper/css/pagination";

// import required modules
import { Grid } from "swiper/modules";
import { CasinotList } from "../../data/Casino.data";
export default function CasinoSliding() {
  return (
    <div className="mySwiper">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        // grid={{
        //   rows: 2,
        //   fill: "row",
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        modules={[Grid]}
        className="mySwiper"
      >
        {CasinotList.map((game, index) => (
          <SwiperSlide className="">
            <div className="slide-border " key={index}>
              <div className="slide-item">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="slide-img">
                    <img title="JILI" alt="JILI" src={game.img} />

                    <div class="hover-play">
                      <h6>JILI</h6>
                      <i class="fas fa-play"></i>
                    </div>
                  </div>
                </a>{" "}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
