export const Gamelist = [
  {
    img: "/assets/img/229.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/godOfWealth.png",
    alt: "game 1",
    gametitle: "god Of Wealth",
    gameprovider: "AFG",
  },
  {
    img: "/assets/img/224.webp",
    alt: "game 1",
    gametitle: "Go Rush",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/133.webp",
    alt: "game 1",
    gametitle: "Good Fortune",
    gameprovider: "CQ9",
  },
  {
    img: "/assets/img/S-RH02.jpg",
    alt: "game 1",
    gametitle: "Royale House",
    gameprovider: "FSP",
  },
  {
    img: "/assets/img/199.webp",
    alt: "game 1",
    gametitle: "Tongits Go",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/23.webp",
    alt: "game 1",
    gametitle: "Candy Baby",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/151.webp",
    alt: "game 1",
    gametitle: "Super Bingo",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/62.webp",
    alt: "game 1",
    gametitle: "Dice",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/66.webp",
    alt: "game 1",
    gametitle: "Lucky Number",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/118.webp",
    alt: "game 1",
    gametitle: "Big Smaller",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/33.webp",
    alt: "game 1",
    gametitle: "FortunePig",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/179.webp",
    alt: "game 1",
    gametitle: "Win Drop",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/47.webp",
    alt: "game 1",
    gametitle: "Charge Buffalo",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/49.webp",
    alt: "game 1",
    gametitle: "Super Ace",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/197.webp",
    alt: "game 1",
    gametitle: "Super Ace",
    gameprovider: "JLI",
  },
];
