import React from "react";
import { useState, useEffect } from "react";
const Jackpot = () => {
  const [number, setNumber] = useState(2000300.0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((prevNumber) => prevNumber + 10.01);
    }, 300);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <section>
        <div className="container jackpot-bg">
          <div className="jackpot-amount">
            PHP{" "}
            <span className=" ">
              {" "}
              {number.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Jackpot;
