import React from "react";

const Chatbot = () => {
  return (
    <div className="chatbot-wrapp">
      <div className="chat-img">
        <a
          href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
          target="_blank"
          rel="noreferrer"
          className=""
        >
          {" "}
        </a>
      </div>
    </div>
  );
};

export default Chatbot;
