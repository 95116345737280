export const CarouselBanner = [
  {
    carousel: "/assets/img/10-daily-deposit-64d31fd8125bd.webp",
    alt: "carousel 1",
  },
  {
    carousel: "/assets/img/daily-deposit-bonus-64d320183c6be.webp",
    alt: "carousel 2",
  },
  {
    carousel: "/assets/img/online-casino-64d320740c401.webp",
    alt: "carousel 3",
  },
  {
    carousel: "/assets/img/online-casino-64d320740c401.webp",
    alt: "carousel 4",
  },
  {
    carousel: "/assets/img/pinaswin88-net-6502b0a68c18e.webp",
    alt: "carousel 5",
  },
  {
    carousel: "/assets/img/pinaswin88-point-system-64d320879a210.webp",
    alt: "carousel 6",
  },
  {
    carousel: "/assets/img/spin-to-win-65255160180a0.webp",
    alt: "carousel 7",
  },
];
