import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer__holder">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div class="footer-col">
                <div class="footer-title">Serbisyo ng Miyembro</div>
                <div class="footer-service">
                  <div class="service-item">
                    <div class="service-icon">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/deposit.svg"
                        width="40px"
                        alt="pinaswin88 deposit"
                      />
                    </div>
                    <div class="service-time">
                      <div class="time-title">DEPOSITO</div>
                      <div class="time-number">
                        <span id="depositTime">3</span>
                        <span>Mga minuto</span>
                      </div>
                      <div class="progress">
                        <div
                          id="depositTimeBar"
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="3"
                          aria-valuemin="0"
                          aria-valuemax="15"
                          // style="width: 20%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="service-item">
                    <div class="service-icon">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/withdraw.svg"
                        width="40px"
                        alt="pinaswin88 withdraw"
                      />
                    </div>
                    <div class="service-time">
                      <div class="time-title">BUMAWI</div>
                      <div class="time-number">
                        <span id="withdrawTime">3</span>
                        <span>Mga minuto</span>
                      </div>
                      <div class="progress">
                        <div
                          id="withdrawTimeBar"
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow="3"
                          aria-valuemin="0"
                          aria-valuemax="15"
                          // style="width: 20%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-col pt-0">
                <div class="footer-title">Kontakin Kami</div>
                <div class="footer-contact">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div class="contact-item">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/social-media-icons/facebook.svg"
                        width="50px"
                        alt="icons"
                      />
                    </div>
                  </a>
                  <a href="https://bj88ph.live/af/Zd946zCP/pinaswinbet">
                    <div class="contact-item">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/social-media-icons/website.svg"
                        width="50px"
                        alt="icons"
                      />
                    </div>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div class="contact-item">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/social-media-icons/telegram.svg"
                        width="50px"
                        alt="icons"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div class="footer-col pt-0">
                <div class="footer-title">Mag-download ng Apps</div>
                <div class="footer-contact">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div class="contact-item">
                      <img
                        src="https://images.linkcdn.cloud/global/game-skin2/asset/social-media-icons/android.svg"
                        width="50px"
                        alt="iconss"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div class="footer-col">
                <div class="footer-title">PROVIDER</div>
                <div class="footer-provider">
                  <div class="provider-icon">
                    <img
                      alt="JILI"
                      title="JILI"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/jli_footer.webp"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Fa Chai"
                      title="Fa Chai"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/fac_footer.webp"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="JDB"
                      title="JDB"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/jdb_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Afb Gaming"
                      title="Afb Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/afg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Spade Gaming"
                      title="Spade Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/spd_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="RED TIGER"
                      title="RED TIGER"
                      src=" https://images.linkcdn.cloud/global/logo-footer/slot/rtr_footer.webp "
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="AIS GAMING"
                      title="AIS GAMING"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/aisg_footer.webp"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="CQ9 Gaming"
                      title="CQ9 Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/cq9_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Playstar"
                      title="Playstar"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/pls_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="FASTSPIN"
                      title="FASTSPIN"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/fastspin_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="HC Game"
                      title="HC Game"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/hcg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="NoLimit City"
                      title="NoLimit City"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/nlc_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Virtual Tech"
                      title="Virtual Tech"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/vrt_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Ameba"
                      title="Ameba"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/amb_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Top Trend Gaming"
                      title="Top Trend Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/ttg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Play N Go"
                      title="Play N Go"
                      src="https://images.linkcdn.cloud/global/logo-footer/slot/png_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="LIVE GAME"
                      title="LIVE GAME"
                      src="https://images.linkcdn.cloud/global/logo-footer/others/lvg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="MIKI Gaming"
                      title="MIKI Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/others/miki_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="SABONG DS88"
                      title="SABONG DS88"
                      src="https://images.linkcdn.cloud/global/logo-footer/others/ds8_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="WM Casino"
                      title="WM Casino"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/wmc_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Dream Gaming"
                      title="Dream Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/drg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="AFB CASINO"
                      title="AFB CASINO"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/afc_footer.webp"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="GD88"
                      title="GD88"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/gd8_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Evolution"
                      title="Evolution"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/evolution_footer.webp"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="Sexy Gaming"
                      title="Sexy Gaming"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/seg_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="LG88"
                      title="LG88"
                      src="https://images.linkcdn.cloud/global/logo-footer/casino/lg8_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="AFB88"
                      title="AFB88"
                      src="https://images.linkcdn.cloud/global/logo-footer/sports/afb_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                  <div class="provider-icon">
                    <img
                      alt="CMD368"
                      title="CMD368"
                      src="https://images.linkcdn.cloud/global/logo-footer/sports/cmd_footer.png"
                      width="100"
                      height="50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-nav">
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              +18
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              Balita
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              Promosyon
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              Tungkol sa amin
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              Kontakin Kami
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              Termino at Kundisyon
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
            >
              FAQ
            </a>
            <a
              href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
              class="footer-link"
              target="_blank"
              rel="noreferrer"
            >
              Affiliate
            </a>
          </div>
          <div class="footer__trademark">
            Copyright © 2021 - 2023 pinaswin88 is an international registered
            trademark. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
