import React from "react";
import Hero from "../components/Home/Hero";
import Jackpot from "../components/Home/Jackpot";
import Popular from "../components/Home/Popular";
import Slot from "../components/Home/Slot";
import Casino from "../components/Home/Casino";
import Sport from "../components/Home/Sport";
import Payment from "../components/Home/Payment";
import Best from "../components/Home/Best";

const Home = () => {
  return (
    <div className="mb-3">
      <Hero />
      <Jackpot />
      <Popular />
      <Slot />
      <Casino />
      <Sport />
      <Payment />
      <Best/>
    </div>
  );
};

export default Home;
