import React from "react";
import PaymentSwiper from "../Carousel/PaymentSwiper";

const Payment = () => {
  return (
    <div>
      {/* <section>
        <div className="container">
          <div class="game-container__header">
            <div class="game-title">
              <div class="icon">
                <img
                  src="https://images.linkcdn.cloud/global/game-skin2/asset/slider-icons/payment-icon.svg"
                  width="80"
                  alt="payment"
                />
              </div>
              <h6>Paraan ng Pagbayad</h6>
            </div>
            <div className="game-container__bank-list">
              <PaymentSwiper />
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="container">
          <div className="game-container__header">
            <div className="game-title">
              <div className="icon">
                <img
                  src="https://images.linkcdn.cloud/global/game-skin2/asset/slider-icons/payment-icon.svg"
                  alt="icon popular"
                  width="80px"
                  srcset=""
                />
              </div>{" "}
              <h6>Paraan ng Pagbayad</h6>
            </div>
            {/* <div class="game-action">
              <div class="action-button">
                <a href="https://bj88ph.live/af/Zd946zCP/pinaswinbet" class="btn-custom-sm nav-link">
                  Iba pang laro
                </a>
              </div>
              <div class="action-navigation">
                <button
                  class="btn-custom-sm navigation-prev--popular swiper-button-disabled"
                  disabled=""
                  tabindex="-1"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-e48af99a37118559"
                  aria-disabled="true"
                >
                  <i class="bi bi-chevron-left"></i>
                </button>
                <button
                  class="btn-custom-sm navigation-next--popular swiper-button-disabled"
                  tabindex="-1"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-e48af99a37118559"
                  aria-disabled="true"
                  disabled=""
                >
                  <i class="bi bi-chevron-right"></i>
                </button>
              </div>
            </div> */}
          </div>
          <div className="game-container__bank-list">
            <PaymentSwiper />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payment;
