import React, { useEffect, useState } from "react";
import { BiStopwatch } from "react-icons/bi";
import { navMenu } from "../../data/Navbar.data";

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every 1000 milliseconds (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Create options for formatting the date
  const dateOptions = {
    weekday: "short", // Display the abbreviated day (e.g., "Tue")
    month: "long", // Display the full month name (e.g., "October")
    day: "numeric", // Display the day of the month (e.g., "17")
  };

  // Format the current date using the options
  const currentDate = currentTime.toLocaleDateString(undefined, dateOptions);

  return (
    <header className="header-main">
      <div>
        <div className="header-top py-2">
          <div className="container my-auto">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <BiStopwatch className="fs-4 me-1" />
                <span className="text-date fw-bold">{currentDate}, </span>
                <span className="text-time  fw-bold ms-1">
                  {currentTime.toLocaleTimeString()}
                </span>
              </div>
              <div className="col d-flex align-items-center ">
                <i class="bi bi-volume-up fs-4 me-4"></i>
                <div className="marquee-wrapper w-100 d-flex align-items-center ">
                  <marquee direction="left" scrollamount="5" behavior="scroll">
                    FOR BETTER AND FASTER TRANSACTION PLEASE TRY OUR NEW DEPOSIT
                    METHOD CHOOSE GCASH (DIRECT) MINIMUM DEPOSIT 50 PHP THANK
                    YOU
                  </marquee>
                </div>
              </div>
              <div className="col-3 d-flex align-items-center">
                <div className="d-flex ">
                  <img
                    src="/assets/img/help.svg"
                    alt="help "
                    className="img-help me-1"
                    srcset=""
                  />
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    className="nav-link"
                    rel="noopener noreferrer"
                  >
                    Tulong
                  </a>
                  <img
                    src="/assets/img/affiliate.svg"
                    alt="help "
                    className="img-help ms-4 me-1"
                    srcset=""
                  />
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    className="nav-link"
                    rel="noopener noreferrer"
                  >
                    Agents
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    className="nav-link my-auto"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/php.svg"
                      alt="help "
                      className="img-help ms-4 me-1"
                      srcset=""
                    />
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    target="_blank"
                    className="nav-link my-auto"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/philippines.webp"
                      alt="help "
                      className="php-flag rounded-2 ms-4 me-1"
                      srcset=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-bottom py-2">
          <div className="container">
            <div className="navbar-holder">
              <div class="header-logo">
                <a href="https://bj88ph.live/af/Zd946zCP/pinaswinbet">
                  <img
                    alt="WebsiteLogo"
                    src="https://images.linkcdn.cloud/V2/774/logo/logo-310610130.png"
                    width="250"
                    height="54"
                    className=""
                  />
                </a>
              </div>
              <div className="navbar-nav">
                {navMenu.map((item) => (
                  <div className="nav-item w-100" key={item}>
                    <a
                      class="nav-link"
                      href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                    >
                      <img src={item.icon} width="80" alt={item.alt} />
                      <div class="nav-link-name">{item.label}</div>
                    </a>
                  </div>
                ))}
              </div>
              <div class="form-button">
                <button
                  class="btn-login"
                  type="button"
                  data-toggle="modal"
                  data-target="#loginModal"
                >
                  Mag log in
                </button>
                <button
                  class="btn-register"
                  onclick="window.location.href = '/'"
                >
                  Magrehistro
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
