// import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/layout/Layout";

import '../src/styles/header.css';
import '../src/styles/home.css';
import '../src/styles/footer.css';
import '../src/styles/fixed.css';

import React from 'react';
 
function App() {
 

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
