export const navMenu = [
  {
    label: "Home",
    icon: "https://pinaswin88.net/themes/5/img/navigation/home.svg",
    alt: "NavBar"
  },
  {
    label: "Slot",
    icon: "https://pinaswin88.net/themes/5/img/navigation/slot.svg",
    alt: "NavBar"
  },
  {
    label: "Live Game",
    icon: "https://pinaswin88.net/themes/5/img/navigation/livegames.svg",
    alt: "NavBar"
  },
  {
    label: "Casino",
    icon: "https://pinaswin88.net/themes/5/img/navigation/casino.svg",
    alt: "NavBar"
  },
  {
    label: "Sportsbook",
    icon: "https://pinaswin88.net/themes/5/img/navigation/sport.svg",
    alt: "NavBar"
  },
  {
    label: "Arcade",
    icon: "https://pinaswin88.net/themes/5/img/navigation/arcade.svg",
    alt: "NavBar"
  },
  {
    label: "Promo",
    icon: "https://pinaswin88.net/themes/5/img/navigation/promotion.svg",
    alt: "NavBar"
  },
  {
    label: "Balita",
    icon: "https://pinaswin88.net/themes/5/img/navigation/news.svg",
    alt: "NavBar"
  },
  {
    label: "Kaganapan",
    icon: "https://pinaswin88.net/themes/5/img/navigation/event.svg",
    alt: "NavBar"
  },
];
