export const CasinotList = [
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/wmc.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/drg.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/afb.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/gd8.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/evo.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/seg.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/casino/lg8.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
];
