import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import "swiper/css/pagination";

// import required modules
import { Grid } from "swiper/modules";
import { Gamelist } from "../../data/Gamelist.data";
export default function SwiperSliding() {
  return (
    <div className="mySwiper">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        // grid={{
        //   rows: 2,
        //   fill: "row",
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        modules={[Grid]}
        className="mySwiper"
      >
        {Gamelist.map((game, index) => (
          <SwiperSlide className="">
            <div className="games-holder swiper-slide" key={index}>
              <img src={game.img} alt={game.alt} srcset="" className="w-100" />
              <div class="hover-play">
                <i class="bi bi-play-fill"></i>
              </div>
              <div class="games-bottom">
                <div class="bottom-info">
                  <div class="info-name">{game.gametitle}</div>
                  <div class="info-provider">{game.gameprovider}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
