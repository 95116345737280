export const SlotList = [
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/slot/jli.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/fac.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/jdb.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/afg.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/spd.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/rtr.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/aisg.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/cq9.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/pls.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/fsp.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/hcg.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "/assets/img/nlc.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
];
