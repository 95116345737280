import { Link } from "react-router-dom";

const HeaderMobile = () => {
  return (
    <header className="header-mobile">
      <div className="header-mobile__top">
        <div className="mobile-button">
          <div className="mobile-button--menu">
            <a href="https://bj88ph.live/af/Zd946zCP/pinaswinbet">
              <img src="/assets/img/menu.svg" width="50px" alt="MenuIcon" />
            </a>
          </div>
        </div>
        <div className="mobile-logo">
          <a href="https://bj88ph.live/af/Zd946zCP/pinaswinbet">
            <img
              src="/assets/img/logo-310610130.webp"
              alt="WebsiteLogo"
              width="125"
              height="27"
            />
          </a>
        </div>
        <div className="mobile-chat">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
          >
            <img
              src="/assets/img/live-chat.svg"
              width="50px"
              alt="WebsiteLogo"
            />
          </a>
        </div>
      </div>

      <div className="header-marquee d-flex">
        <div className="marquee-icon">
          <img src="/assets/img/broadcast.svg" alt="broadcast" className="" />
        </div>
        <div style={{ overflow: "hidden" }}>
          <p className="marquee mb-0 ms-2">
            FOR BETTER AND FASTER TRANSACTION PLEASE TRY OUR NEW DEPOSIT METHOD
            CHOOSE GCASH (DIRECT) MINIMUM DEPOSIT 50 PHP THANK YOU
          </p>
        </div>
      </div>
      <div className="header-mobile__login">
        <div className="footer-button">
          <button
            className="btn rounded-0 btn-login mobile-login"
            type="button"
            onClick={() =>
              (window.location.href =
                "https://bj88ph.live/af/Zd946zCP/pinaswinbet")
            }
          >
            Mag log in
          </button>
          <button
            className="btn rounded-0 btn-danger btn-register"
            onClick={() =>
              (window.location.href =
                "https://bj88ph.live/af/Zd946zCP/pinaswinbet")
            }
          >
            Magrehistro
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderMobile;
