export const SportList = [
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/sportbook/afb88.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
  {
    img: "https://images.linkcdn.cloud/global/game-skin2/banner/sportbook/cmd368.webp",
    alt: "game 2",
    gametitle: "Mines",
    gameprovider: "JLI",
  },
];
