import React from "react";
import CasinoSliding from "../Carousel/Casino";

const Casino = () => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="game-container__header">
            <div className="game-title">
              <div className="icon">
                <img
                  src="https://pinaswin88.net/themes/5/img/navigation/casino.svg"
                  alt="icon popular"
                  width="80px"
                  srcset=""
                />
              </div>{" "}
              <h6>Casino</h6>
            </div>
            <div class="game-action">
              <div class="action-button">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/pinaswinbet"
                  class="btn-custom-sm nav-link"
                >
                  Iba pang laro
                </a>
              </div>
              <div class="action-navigation">
                <button
                  class="btn-custom-sm navigation-prev--popular swiper-button-disabled"
                  disabled=""
                  tabindex="-1"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-e48af99a37118559"
                  aria-disabled="true"
                  onClick={() =>
                    (window.location.href =
                      "https://bj88ph.live/af/Zd946zCP/pinaswinbet")
                  }
                >
                  <i class="bi bi-chevron-left"></i>
                </button>
                <button
                  class="btn-custom-sm navigation-next--popular swiper-button-disabled"
                  tabindex="-1"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-e48af99a37118559"
                  aria-disabled="true"
                  disabled=""
                  onClick={() =>
                    (window.location.href =
                      "https://bj88ph.live/af/Zd946zCP/pinaswinbet")
                  }
                >
                  <i class="bi bi-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="game-container__category-list">
            <CasinoSliding />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Casino;
