// import React from "react";

// const PaymentSwiper = () => {
//   return (
//     <div>
//       <div className="bank-holder">
//         <div class="bank-status online">ONLINE</div>
//         <div class="bank-img">
//           <img src="https://images.linkcdn.cloud/global/payment/V2/PHP/epayment/gcash.webp" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentSwiper;
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import "swiper/css/pagination";

// import required modules
import { Grid } from "swiper/modules";
import { SlotList } from "../../data/Slot.data";
import { PaymentList } from "../../data/Payment.data";
export default function PaymentSliding() {
  return (
    <div className="mySwiper">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        // grid={{
        //   rows: 2,
        //   fill: "row",
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        modules={[Grid]}
        className="mySwiper"
      >
        {PaymentList.map((payment, index) => (
          <SwiperSlide className=" ">
            <div className="bank-holder" key={index}>
              <div class="bank-status online">ONLINE</div>

              <div class="bank-img">
                <img src={payment.img} className="w-100" alt={payment.alt} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
