import React from "react";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Chatbot from "../header/Fixed";
import Modal from "../Home/Modal";
import Headerm from "../header/Headerm";

const Layout = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisited");

    if (!hasVisitedBefore) {
      setShowModal(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Modal show={showModal} onHide={hideModal} />
      <Header />
      <Headerm />
      <Outlet /> <Chatbot />
      <Footer />
    </div>
  );
};

export default Layout;
