import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { CarouselBanner } from "../../data/Carousel.data";
const Hero = () => {
  return (
    <div>
      <section>
        <div className="container my-2">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            loop={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          
          >
            {CarouselBanner.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item.carousel}
                  alt={item.alt}
                  srcset=""
                  className="w-100 rounded-4"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Hero;
